import React, { useEffect, useRef } from "react";
import styled from "styled-components";
import { useSelector, useDispatch } from "react-redux";
import { db } from "../firebase";
import { useNavigate } from "react-router-dom";
import {
  collection,
  setDoc,
  doc,
  updateDoc,
  serverTimestamp,
  query,
  where,
  getDocs,
} from "firebase/firestore";
import {
  setBuyerName,
  setFirstname,
  setLastname,
  setPhoneNumber,
  setEmail,
  setCity,
  setAdress,
  setOther,
  setCoupon,
  setDiscount,
  setDelivery,
  setItems,
  setComment,
  setBank,
  setItemName,
  setQnt,
  setItemPrice,
  setSum,
} from "../redux/placeOrder.js";
import { ClearCart, setCartList } from "../redux/products";
import axios from "axios";

const Success = () => {
  // defines some inside functions
  const dispatch = useDispatch();
  const navigate = useNavigate();

  // scroll to top when page loads
  React.useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  // defines current user state from redux
  const currentUser = useSelector((state) =>
    state.storeMain.user
      ? JSON.parse(state.storeMain.user)
      : state.storeMain.user
  );

  /**
   * defines items from localstorage saved before transaction
   */

  // cart items
  const cart = JSON.parse(localStorage.getItem("cart:elan-ecommerce"));
  // all product list
  const products = JSON.parse(localStorage.getItem("products:elan-ecommerce"));
  // payId
  const orderDetails = JSON.parse(
    localStorage.getItem("orderDetails:elan-ecommerce")
  );

  const [payData, setPayData] = React.useState("");

  /**
   * Get payments Details
   */
  const getPaymentDetails = async () => {
    try {
      if (orderDetails?.orderNumber) {
        const response = await axios.get(
          // "http://localhost:5000/api/payments",
          "https://elan-georgia-backend.herokuapp.com/api/payments",
          {
            params: {
              payId: orderDetails?.payId,
              token: orderDetails?.token,
              orderNumber: orderDetails?.orderNumber,
              orderInfo: orderDetails?.orderInfo,
            },
          }
        );

        if (response.status !== 200) {
          throw new Error("Network response was not ok");
        }

        const data = response.data; // With axios, you get data directly

        await UpdateOrder(
          data?.data?.status, // Adjust based on actual response structure
          orderDetails?.orderNumber,
          cart,
          products
        );
        await CleanCarts(data?.data?.status); // Adjust based on actual response structure
        setPayData(data);
      }
    } catch (err) {
      console.error("There was a problem with your fetch operation:", err);
    }
  };

  const UpdateOrder = (id, o, c, p) => {
    if (id === "WaitingConfirm") {
      const orderRef = doc(db, "orders", `${o}`);
      updateDoc(orderRef, {
        status: "New Order",
      });
      const book = c.find((bookItem) =>
        bookItem.name?.toLowerCase().includes("book")
      );
      if (c?.length > 1 && book) {
        const cart = c.filter(
          (item, index) => !item.name?.toLowerCase().includes("book")
        );
        cart?.map((item) => {
          const initialQnt = p.find((it) => item.name === it.name);
          const product = doc(db, "products", `${item.name}`);
          if (product) {
            updateDoc(product, {
              inStock: initialQnt.inStock - item.quantity,
            });
            const userDb = doc(db, "users", `${currentUser.id}`);
            updateDoc(userDb, {
              book: book,
            });
          }
        });
      } else if (c?.length === 1 && book) {
        const userDb = doc(db, "users", `${currentUser.id}`);
        updateDoc(userDb, {
          book: book,
        });
      } else {
        c?.map((item) => {
          const initialQnt = p.find((it) => item.name === it.name);
          const product = doc(db, "products", `${item.name}`);
          if (product) {
            updateDoc(product, {
              inStock: initialQnt.inStock - item.quantity,
            });
          }
        });
      }
    }
  };

  const CleanCarts = (x) => {
    if (x === "WaitingConfirm") {
      localStorage.removeItem("cart:elan-ecommerce");
      dispatch(setCartList([]));
    }
    localStorage.removeItem("orderDetails:elan-ecommerce");
    localStorage.removeItem("orderNumber:elan-ecommerce");
  };

  useEffect(() => {
    getPaymentDetails();
  }, []);

  let lastText;
  if (payData?.data?.status) {
    if (payData?.data?.status === "WaitingConfirm") {
      lastText = "Thank You! გადახდა წარმატებით შესრულდა";
    } else {
      lastText = "გადახდა არ შესრულდა!";
    }
  }

  return (
    <div
      style={{
        height: "100vh",
        width: "100%",
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        justifyContent: "center",
        gap: "20px",
      }}
    >
      {lastText}
      <Button
        onClick={() => {
          navigate("/");
        }}
      >
        მთავარზე დაბრუნება
      </Button>
    </div>
  );
};

export default Success;

const Button = styled.div`
  font-size: 0.8vw;
  width: 12vw;
  height: 2vw;
  border-radius: 5vw;
  border: 1px solid #ccc;
  background: #31a65e;
  color: white;
  font-weight: bold;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-top: 1vw;
  padding-bottom: 0.1vw;
  cursor: pointer;
  text-align: center;

  @media only screen and (max-width: 600px) {
    font-size: 3vw;
    letter-spacing: 0.2vw;
    width: 40vw;
    height: 8vw;
    border-radius: 10vw;
  }

  :hover {
    filter: brightness(1.1);
  }
`;
