import React, { useState, useContext, useEffect } from "react";
import styled from "styled-components";
import { Mobile, MobileSmall } from "../responsive";
import { useSelector, useDispatch } from "react-redux";
import { ClearCart, setCart, Increment, Decriment } from "../redux/products";
import { MdRemove } from "react-icons/md";
import { removeItem } from "../redux/products";
import { Link } from "react-router-dom";
import { BsBoxArrowInLeft } from "react-icons/bs";
import { AuthContext } from "../context/AuthContext";
import { useNavigate } from "react-router-dom";
import {
  collection,
  query,
  where,
  getDoc,
  getDocs,
  setDoc,
  doc,
  updateDoc,
  serverTimestamp,
} from "firebase/firestore";

import { db } from "../firebase";
import {
  setBuyerName,
  setFirstname,
  setLastname,
  setPhoneNumber,
  setEmail,
  setCity,
  setAdress,
  setOther,
  setCoupon,
  setDiscount,
  setDelivery,
  setItems,
  setComment,
  setBank,
  setItemName,
  setQnt,
  setItemPrice,
  setSum,
} from "../redux/placeOrder.js";
import { Coupon } from "../components/coupon";
import axios from "axios";
import { setServer } from "../redux/server";
import tbc from "../assets/tbc.png";
import visa from "../assets/visa.png";
import mastercard from "../assets/mastercard.png";
import { rus, eng, geo } from "../languages/pages/checkout";

const Checkout = () => {
  /**
   * on load checkout page scroll to top
   */
  React.useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  /** define language
   */
  let language;
  const lang = useSelector((state) => state.storeMain.language);
  if (lang == "eng") {
    language = eng;
  } else if (lang == "geo") {
    language = geo;
  } else {
    language = rus;
  }

  // loading spinner state
  const [loading, setLoading] = useState(true);

  // some imports
  const dispatch = useDispatch();
  const navigate = useNavigate();

  // main current currentUser
  const currentUser = useSelector(
    (state) => state.storeMain.user && JSON.parse(state.storeMain.user)
  );

  /**
   * ------------------------------------------------------------------------------------------------------
   */

  // order info in redux state
  const orderInfo = useSelector((state) => state.storeOrder);
  // cart items state
  const cartItems = useSelector((state) => state.storeProducts.cart);

  /**
   * defines order total sum
   */
  const DefineSum = () => {
    let sum = [];
    const result = cartItems?.map((item) => {
      return sum.push(item.quantity * item.price);
    });
    const initialValue = 0;
    const sumWithInitial = sum.reduce(
      (previousValue, currentValue) => previousValue + currentValue,
      initialValue
    );
    return sumWithInitial;
  };

  const sum = DefineSum();

  /**
   * if current currentUser is authenticated, add that currentUser's info in orders details
   */

  const DefineCurrentUserInfo = () => {
    if (currentUser) {
      dispatch(
        setBuyerName(currentUser?.firstname + " " + currentUser?.lastname)
      );
      dispatch(setFirstname(currentUser?.firstname));
      dispatch(setLastname(currentUser?.lastname));
      dispatch(setEmail(currentUser?.email));
      dispatch(setPhoneNumber(currentUser?.phone));
      dispatch(setCity(currentUser?.adress.city));
      dispatch(setAdress(currentUser?.adress.adress));
      dispatch(setItems(cartItems));
      dispatch(setBank("TBC e-commerce"));
      dispatch(setSum(sum));
      if (currentUser?.adress.city == "TBILISI") {
        dispatch(setDelivery("6"));
      } else {
        dispatch(setDelivery("10"));
      }
      // if (
      //   currentUser?.coupon?.couponName?.length > 0 &&
      //   currentUser?.coupon?.couponName?.length != undefined
      // ) {
      //   dispatch(setCoupon(currentUser?.coupon));
      // } else {
      dispatch(setCoupon({ couponName: "", couponPercent: 0, owner: "" }));
      // }
    } else {
      dispatch(setCity("tbilisi"));
      dispatch(setDelivery("6"));
      dispatch(setItems(cartItems));
      dispatch(setBank("TBC e-commerce"));
      dispatch(setSum(sum));
      dispatch(setCoupon({ couponName: "", couponPercent: 0, owner: "" }));
    }
  };

  useEffect(() => {
    DefineCurrentUserInfo();
    setLoading(false);
  }, []);

  /**
   *
   *
   * ---------------------------------------------------------------------------------------------------------
   *
   *
   */

  /**
   * defines delivery
   */
  const DefineDelivery = () => {
    let delivery;
    if (orderInfo?.city?.toLowerCase() == "tbilisi") {
      delivery = (
        <fieldset onChange={(e) => dispatch(setDelivery(e.target.value))}>
          <legend>{language.choiceDelivery}:</legend>
          <div style={{ marginTop: "0.5vw" }}>
            <input
              type="radio"
              id="standart"
              value="6"
              name="delivery"
              defaultChecked={true}
              required
            />
            <span>{language.standart}</span>
          </div>

          <div style={{ marginTop: "0.5vw" }}>
            <input
              type="radio"
              id="express"
              value="12"
              name="delivery"
              required
            />
            <span>{language.express}</span>
          </div>
          {/* 
          <div style={{ marginTop: "0.5vw" }}>
            <input
              type="radio"
              id="pickup"
              value="0"
              name="delivery"
              required
            />
            <span>{language.pickUp}</span>
          </div> */}
        </fieldset>
      );
    } else if (
      orderInfo?.city?.length > 3 &&
      orderInfo?.city.toLowerCase() != "tbilisi"
    ) {
      delivery = (
        <fieldset>
          <legend>{language.delivery}:</legend>
          <div>
            <span>{language.regions}</span>
          </div>
        </fieldset>
      );
    } else {
      delivery = undefined;
    }
    return delivery;
  };

  const Delivery = DefineDelivery();

  let deliveryDefined;
  var bookItem = orderInfo?.items.find((item) =>
    item.name?.toLowerCase().includes("book")
  );
  if (orderInfo?.items.length === 1 && bookItem != undefined) {
    deliveryDefined = "0";
    dispatch(setDelivery("0"));
  } else {
    if (orderInfo?.city?.toLowerCase() == "tbilisi") {
      if (orderInfo?.delivery?.length > 0) {
        deliveryDefined = orderInfo?.delivery;
      } else {
        deliveryDefined = "6";
      }
    } else if (
      orderInfo?.city?.length > 3 &&
      orderInfo?.city.toLowerCase() != "tbilisi"
    ) {
      deliveryDefined = "10";
    } else {
      deliveryDefined = "";
    }
  }

  // coupon state
  const coupon = useSelector((state) => state.storeOrder.coupon);

  // define total with delivery and coupon
  const DefineTotal = () => {
    let tot;
    if (deliveryDefined?.length > 0) {
      tot =
        parseInt(sum) -
        (sum / 100) * coupon?.couponPercent +
        parseInt(deliveryDefined);
    } else {
      tot = parseInt(sum) - (sum / 100) * coupon?.couponPercent;
    }

    return tot;
  };

  const total = DefineTotal();

  /**
   * ----------------------------------------------------------------------------------------------
   */
  /**
   * gettings all order and defines new order number
   */
  // getting unparsed all orders
  const orderL = useSelector((state) => state.storeDashboard.orderItems);
  // parse unparsed orders
  const orderList = JSON.parse(orderL);
  // sort orders by orderNumber
  const orders = orderList?.sort((a, b) => a.orderNumber - b.orderNumber);
  // get cart items
  const itemList = useSelector((state) => state.storeProducts.cart);
  // get all products list
  const products = useSelector((state) => state.storeProducts.list);

  // defines last item in list
  let lastItem;
  if (orders?.length > -1) {
    lastItem = orders[orders?.length - 1];
  }

  // defines new order number
  let num;
  if (orders?.length < 1) {
    num = 1;
  } else if (orders?.length == 1) {
    num = orders[0]?.orderNumber + 1;
  } else {
    num = lastItem?.orderNumber + 1;
  }

  /**
   * --------------------------------------------------------------------------------------------------
   */

  // tbc checkout
  const buyItem = async () => {
    try {
      setLoading(true);
      const req = await axios.post(
        "http://localhost:8000/api/payments",
        // "https://elan-georgia-backend.herokuapp.com/api/payments",
        {
          total,
          orderInfo: orderInfo,
          orderNumber: num,
        }
      );
      if (req.data) {
        await AddOrderToFirebase();
        localStorage.setItem(
          "orderDetails:elan-ecommerce",
          JSON.stringify({
            orderNumber: num,
            payId: req.data.data.payId,
            token: req.data.token,
            orderInfo: orderInfo,
          })
        );
        localStorage.setItem(
          "products:elan-ecommerce",
          JSON.stringify(products)
        );
        window.location.href = req.data.data?.links[1]?.uri;
      }
      setTimeout(() => {
        setLoading(false);
      }, 1000);
    } catch (error) {
      console.log(error);
    }
  };

  /**
   * Add order to firebase data
   */
  async function AddOrderToFirebase() {
    const orderList = collection(db, `orders`);
    await setDoc(doc(orderList, `${num}`), {
      buyer: orderInfo?.buyerName,
      phoneNumber: orderInfo?.phoneNumber,
      email: orderInfo?.email,
      city: orderInfo?.city,
      adress: orderInfo?.adress,
      ["items"]: orderInfo?.items,
      coupon:
        orderInfo?.coupon != undefined ? orderInfo?.coupon.couponName : "",
      delivery: deliveryDefined,
      discount:
        orderInfo?.coupon != undefined ? orderInfo?.coupon.couponPercent : "",
      orderNumber: num,
      status: "Not Finished",
      orderTime: serverTimestamp(),
      sum: sum,
      bank: "TBC e-commerce",
      comment: orderInfo?.comment,
    });
  }

  return (
    <Container>
      <Back>
        <div
          onClick={() => navigate("/cart")}
          style={{
            display: "flex",
            alignItems: "center",
            cursor: "pointer",
            color: "inherit",
          }}
        >
          <BsBoxArrowInLeft className="backIcon" />
          <span>{language.back}</span>
        </div>
      </Back>
      <Wrapper>
        <Form>
          <h3>{language.checkout}</h3>
          <h4>{language.name}</h4>
          <Input
            type="text"
            placeholder={language.name}
            onChange={(e) => dispatch(setBuyerName(e.target.value))}
            value={orderInfo?.buyerName}
            required
          />
          <h4>{language.email}</h4>
          <Input
            type="text"
            placeholder={language.email}
            onChange={(e) => dispatch(setEmail(e.target.value))}
            value={orderInfo?.email}
            required
          />
          <h4>{language.phone}</h4>
          <Input
            type="text"
            placeholder={language.phone}
            onChange={(e) => dispatch(setPhoneNumber(e.target.value))}
            value={orderInfo?.phoneNumber}
            required
          />
          <h4>{language.city}</h4>
          <Select
            list="cities"
            type="text"
            placeholder={language.city}
            onChange={(e) => dispatch(setCity(e.target.value))}
            value={orderInfo?.city}
            required
            className="select"
          >
            {/* <datalist id="cities" className="selectItems"> */}
            <option>TBILISI</option>
            <option>BATUMI</option>
            <option>KUTAISI</option>
            <option>ZUGDIDI</option>
            <option>POTI</option>
            <option>TSALENDJIKHA</option>
            <option>RUSTAVI</option>
            <option>AKHALTSIKHE</option>
            <option>SAMTREDIA</option>
            <option>OZURGETI</option>
            <option>ZESTAPONI</option>
            <option>MESTIA</option>
            <option>TELAVI</option>
            <option>MTSKHETA</option>
            <option>GORI</option>
            <option>BORJOMI</option>
            <option>KOBULETI</option>
            <option>SENAKI</option>
            <option>ABASHA</option>
            <option>MARTVILI</option>
            <option>SAGAREJO</option>
            <option>MARNEULI</option>
            <option>TSKALTUBO</option>
            {/* </datalist> */}
          </Select>
          <h4>{language.adress}</h4>
          <Input
            type="text"
            placeholder={language.adress}
            onChange={(e) => dispatch(setAdress(e.target.value))}
            value={orderInfo?.adress}
            required
          />
          <h4>{language.comment}</h4>
          <InputText
            placeholder={language.comment}
            onChange={(e) => dispatch(setComment(e.target.value))}
            value={orderInfo?.comments}
          />
        </Form>
        <Coupon
          languageCoupon={language.coupon}
          languageDiscount={language.discount}
          languageGel={language.gel}
          user={currentUser}
          sum={sum}
          user={currentUser}
        />
        <OrderContainer>
          <h3 style={{ marginTop: "50px" }} id="yourOrder">
            {language.order}:
          </h3>
          <ItemList>
            {cartItems?.map((item, index) => {
              return (
                <ItemContainer key={index}>
                  <ItemName>
                    <span>{item.name}</span>
                  </ItemName>
                  <ItemQnt>
                    <span style={{ marginRight: "0.2vw" }}>
                      {item.quantity}
                    </span>{" "}
                    {language.pcs}
                  </ItemQnt>
                  <ItemPrice>
                    <span>
                      {item.price} {language.gel}.{" "}
                    </span>
                  </ItemPrice>
                  <ItemPrice>
                    <span>
                      {item.price * item.quantity} {language.gel}.{" "}
                    </span>
                  </ItemPrice>
                </ItemContainer>
              );
            })}
          </ItemList>
          <Subtotal>
            {language.subtotal}: {sum} {language.gel}
          </Subtotal>
          <Coupon
            languageCoupon={language.coupon}
            languageDiscount={language.discount}
            languageGel={language.gel}
            user={currentUser}
            sum={sum}
            user={currentUser}
            desktop={true}
          />
          {orderInfo?.items.length === 1 && bookItem != undefined ? null : (
            <div style={{ marginTop: "2vw" }} className="deliveryDesktop">
              {Delivery}
            </div>
          )}
          <Total total={true}>
            {language.total}: {total} {language.gel}
          </Total>
        </OrderContainer>
      </Wrapper>
      <div id="icons">
        <img src={tbc} alt="tbc" className="icons" />
        <img src={visa} alt="visa" className="icons" />
        <img src={mastercard} alt="mastercard" className="icons" />
      </div>
      {loading ? (
        <Button load={true}>{language.loading}</Button>
      ) : (
        <Button
          onClick={
            cartItems?.length > 0 &&
            deliveryDefined?.length > 0 &&
            orderInfo?.buyerName?.length > 0 &&
            orderInfo?.city?.length > 2 &&
            orderInfo?.adress?.length > 3 &&
            orderInfo?.phoneNumber?.length > 6 &&
            orderInfo?.email?.length > 6
              ? async () => {
                  buyItem();
                  // await AddOrderToFirebase();
                }
              : () => alert("PLease Input fields")
          }
        >
          <div className="button">{language.buy}</div>
        </Button>
      )}
    </Container>
  );
};

export default Checkout;

const Container = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: start;
  align-items: center;
  min-height: 100vh;
  padding-top: 7vw;
  padding-bottom: 5vh;

  @media only screen and (max-width: 621px) {
    padding-top: 22vw;
    position: sticky;
    background: white;
    height: auto;
    min-height: 30vh;
  }

  #icons {
    margin-top: 50px;
    @media only screen and (max-width: 621px) {
      margin-top: 25px;
    }
  }

  .icons {
    height: 60px;
  }
`;

const Wrapper = styled.div`
  width: 90vw;
  display: flex;
  align-items: start;
  justify-content: space-evenly;

  @media only screen and (max-width: 621px) {
    flex-direction: column;
    align-items: center;
  }

  .mobile {
    display: none;

    @media only screen and (max-width: 621px) {
      display: flex;
    }
  }
`;

const Title = styled.h4`
  font-size: 1.7vw;
  letter-spacing: 0.05vw;

  @media only screen and (max-width: 621px) {
    display: none;
  }
`;
const Form = styled.form`
  display: flex;
  align-items: center;
  flex-direction: column;
  gap: 1vw;

  @media only screen and (max-width: 621px) {
    width: 85vw;
    gap: 3vw;
  }

  & > h4 {
    margin: 0;
  }

  .select {
    border-radius: 50vw;
    width: 25vw;
    @media only screen and (max-width: 600px) {
      width: 70vw;
      height: 7vw;
      padding: 1.5vw;
    }
  }
`;
const Names = styled.div`
  width: 25vw;
  display: flex;
  justify-content: space-between;

  @media only screen and (max-width: 621px) {
    width: 85vw;
  }
`;
const NamesInput = styled.input`
  font-size: 1vw;
  width: 12vw;
  height: 2.5vw;
  border: 1px solid #ccc;
  background: white;
  border-radius: 5vw;
  padding: 0;
  text-align: center;
  color: #222;

  @media only screen and (max-width: 600px) {
    width: 30vw;
    height: 7vw;
    border-radius: 50vw;
    gap: 4vw;
    padding: 1.5vw;

    font-size: 16px;
  }

  &:focus {
    outline: none;
  }
`;

const Input = styled.input`
  font-size: 1vw;
  width: 25vw;
  height: 2.5vw;
  border: 1px solid #ccc;
  background: white;
  border-radius: 5vw;
  padding: 0;
  text-align: center;
  color: #222;

  @media only screen and (max-width: 600px) {
    width: 70vw;
    height: 7vw;
    border-radius: 50vw;
    gap: 1.5vw;
    padding: 1.5vw;
    font-size: 16px;
  }

  &:focus {
    outline: none;
  }
`;
const InputText = styled.textarea`
  font-size: 1vw;
  width: 25vw;
  height: 2vw;
  border: none;
  border-radius: 0.5vw;
  padding: 2vh 0 0 0;
  text-align: center;
  background: #fff;
  border: 1px solid #ccc;

  &:focus {
    outline: none;
  }

  @media only screen and (max-width: 621px) {
    width: 70vw;
    height: 9vw;
    font-size: 16px;
    letter-spacing: 0.12vw;
    border-radius: 50vw;
    padding: 1.5vw;
  }
`;
const Select = styled.select`
  font-size: 1vw;
  width: 25vw;
  height: 3vw;
  border-radius: 0.5vw;
  padding: 0;
  text-align: center;
  background: #fff;
  border: 1px solid #ccc;

  @media only screen and (max-width: 621px) {
    width: 85vw;
    height: 9vw;
    font-size: 16px;
    letter-spacing: 0.12vw;
    border-radius: 50vw;
  }

  &:focus {
    outline: none;
  }
`;

const Button = styled.div`
  font-size: 0.8vw;
  width: 12vw;
  height: 2vw;
  border-radius: 5vw;
  border: 1px solid #ccc;
  background: ${(props) => (props.load ? "#ccc" : "#31a65e")};
  color: white;
  font-weight: bold;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-top: 1vw;
  padding-bottom: 0.1vw;
  cursor: pointer;

  @media only screen and (max-width: 600px) {
    padding: 0.9vw 2vw 1.2vw 2vw;
    border-radius: 50vw;
    width: 40vw;
    height: 6vw;
    font-size: 3vw;
  }

  :hover {
    filter: brightness(1.1);
  }
`;

const OrderContainer = styled.div`
  #yourOrder {
    @media only screen and (max-width: 621px) {
      display: none;
    }
  }
  .deliveryDesktop {
    @media only screen and (max-width: 621px) {
      // display: none;
    }
  }
`;

const Back = styled.div`
  display: flex;
  justify-content: space-between;
  margin-bottom: 0vw;

  .backIcon {
    font-size: 1vw;
    margin-right: 0.5vw;

    @media only screen and (max-width: 621px) {
      margin-right: 1.5vw;
      font-size: 4vw;
    }
  }

  @media only screen and (max-width: 621px) {
    margin: 5vw 0 0 0;
  }
`;

const ItemList = styled.div`
  display: flex;
  flex-direction: column;
  gap: 0.25vw;
  overflow-y: scroll;
  height: auto;

  @media only screen and (max-width: 621px) {
    display: none;
  }
`;

const ItemContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: start;
  padding: 1vw 1vw 0 0;

  @media only screen and (max-width: 621px) {
    gap: 3vw;
    padding: 1vw 1vw 2vw 0;
  }
`;

const ItemName = styled.div`
  display: flex;
  width: 14vw;
  overflow-x: hidden;

  @media only screen and (max-width: 621px) {
    width: 42vw;
    font-size: 16px;
  }
`;

const ItemQnt = styled.div`
  display: flex;
  justify-content: start;
  width: 4vw;
  font-weight: bold;

  @media only screen and (max-width: 621px) {
    font-size: 16px;
  }
`;
const ItemPrice = styled.div`
  display: flex;
  justify-content: center;
  width: 5vw;

  @media only screen and (max-width: 621px) {
    width: 15vw;
    font-size: 16px;
  }
`;

const Subtotal = styled.div`
  padding-top: 0.5vw;
  border-top: 2px solid #ccc;
  margin-top: 2vw;
  font-weight: ${(props) => props.total && "bold"};

  @media only screen and (max-width: 621px) {
    display: none;
  }
`;
const Total = styled.div`
  padding-top: 0.5vw;
  border-top: 2px solid #ccc;
  margin-top: 2vw;
  font-weight: ${(props) => props.total && "bold"};

  @media only screen and (max-width: 621px) {
    margin-top: 5vw;
    padding: 2vw 0;
    font-size: 18px;
  }
`;
