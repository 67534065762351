import React from "react";
import { useSelector } from "../../redux/main";

export const eng = {
  home: "Home",
  logout: "Logout",
  myPage: "My Page",
  shop: "Shop",
  book: "ELAN Brow Book",
  events: "Events",
  artists: "ELAN Artists",
  contact: "Contact",
  about: "About ELAN",
  login: "Log in",
};

export const geo = {
  home: "მთავარი",
  logout: "გასვლა",
  myPage: "ჩემი გვერდი",
  shop: "მაღაზია",
  book: "ELAN Brow Book",
  events: "ღონისძიებები",
  artists: "ELAN არტისტები",
  contact: "კონტაქტი",
  about: "ELAN ის შესახებ",
  login: "შესვლა",
};

export const rus = {
  home: "Главная",
  logout: "Выйти",
  myPage: "Моя страница",
  shop: "Магазин",
  book: "ELAN Brow Book",
  events: "Мероприятие",
  artists: "ELAN Артисты",
  contact: "Контакт",
  about: "О ELAN",
  login: "Авторизоваться",
};
