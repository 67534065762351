import { initializeApp } from "firebase/app";
import { getFirestore } from "firebase/firestore";
import { getAuth } from "firebase/auth";
import { getAnalytics } from "firebase/analytics";
import { getMessaging, getToken } from "firebase/messaging";

const firebaseConfig = {
  apiKey: "AIzaSyAjL3-Xz_p7liQCIImRhzv5RI4jBnwVhBQ",
  authDomain: "elan-ecommerce.firebaseapp.com",
  projectId: "elan-ecommerce",
  storageBucket: "elan-ecommerce.appspot.com",
  messagingSenderId: "954865463054",
  appId: "1:954865463054:web:f4dcf6a1af37cf791df15f",
};

const app = initializeApp(firebaseConfig);
const analytics = getAnalytics(app);

// function requestPermission() {
//   console.log("Requesting permission...");
//   Notification.requestPermission().then((permission) => {
//     if (permission === "granted") {
//       console.log("Notification permission granted.");
//       const messaging = getMessaging(app);
//       getToken(messaging, {
//         vapidKey:
//           "BDN24hT1e6K50d1gMBC50Wblm9RLKw-2E1Hxym2C0kjTxgQ23wB3GnmJB5L0rZo4pv9CMAsej2guV_W-IL2KeDM",
//       }).then((currentToken) => {
//         if (currentToken) {
//           console.log("currentTokem: " + currentToken);
//         } else {
//           console.log("Token Not found");
//         }
//       });
//     } else {
//       console.log("Don't have permission");
//     }
//   });
// }

// requestPermission();

export const db = getFirestore(app);
export const auth = getAuth();
