import React from "react";
import styled from "styled-components";
import Accordion from "@mui/material/Accordion";
import AccordionSummary from "@mui/material/AccordionSummary";
import AccordionDetails from "@mui/material/AccordionDetails";
import Typography from "@mui/material/Typography";
import { Rrus, Reng, Rgeo } from "../languages/pages/events/basicCourseRus";
import { Grus, Geng, Ggeo } from "../languages/pages/events/basicCourseGeo";
import {
  LGrus,
  LGeng,
  LGgeo,
} from "../languages/pages/events/LevelUpLamiTintGeo";
import {
  LRrus,
  LReng,
  LRgeo,
} from "../languages/pages/events/LevelUpLamiTintRus";
import {
  MGrus,
  MGeng,
  MGgeo,
} from "../languages/pages/events/LevelUpModTintGeo";
import {
  MRrus,
  MReng,
  MRgeo,
} from "../languages/pages/events/LevelUpModTintRus";
import { useSelector, useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import {
  setEventTitle,
  setEventPrice,
  setEventDate,
} from "../redux/placeOrder";
import cover1 from "../assets/events/კ-რუსულად.jpg";
import cover2 from "../assets/events/კ-ქართულად.jpg";
import cover3 from "../assets/events/ლ-რუსულად.jpg";
import cover4 from "../assets/events/ლ-ქართულად.jpg";
import cover5 from "../assets/events/პ-რუსულად.jpg";
import cover6 from "../assets/events/პ-ქართულად.jpg";

const Events = () => {
  React.useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  const navigate = useNavigate();
  const dispatch = useDispatch();
  // define BasicCourseRusLanguage
  let BasicCourseRusLanguage;
  const lang = useSelector((state) => state.storeMain.language);
  if (lang == "eng") {
    BasicCourseRusLanguage = Reng;
  } else if (lang == "geo") {
    BasicCourseRusLanguage = Rgeo;
  } else {
    BasicCourseRusLanguage = Rrus;
  }
  // define BasicCourseGeoLanguage
  let BasicCourseGeoLanguage;
  if (lang == "eng") {
    BasicCourseGeoLanguage = Geng;
  } else if (lang == "geo") {
    BasicCourseGeoLanguage = Ggeo;
  } else {
    BasicCourseGeoLanguage = Grus;
  }
  // define LevelUpLamiGeo
  let LevelUpLamiGeo;
  if (lang == "eng") {
    LevelUpLamiGeo = LGeng;
  } else if (lang == "geo") {
    LevelUpLamiGeo = LGgeo;
  } else {
    LevelUpLamiGeo = LGrus;
  }
  // define LevelUpLamiRus
  let LevelUpLamiRus;
  if (lang == "eng") {
    LevelUpLamiRus = LReng;
  } else if (lang == "geo") {
    LevelUpLamiRus = LRgeo;
  } else {
    LevelUpLamiRus = LRrus;
  }
  // define LevelUpModiGeo
  let LevelUpModiGeo;
  if (lang == "eng") {
    LevelUpModiGeo = MGeng;
  } else if (lang == "geo") {
    LevelUpModiGeo = MGgeo;
  } else {
    LevelUpModiGeo = MGrus;
  }
  // define LevelUpModiRus
  let LevelUpModiRus;
  if (lang == "eng") {
    LevelUpModiRus = MReng;
  } else if (lang == "geo") {
    LevelUpModiRus = MRgeo;
  } else {
    LevelUpModiRus = MRrus;
  }

  //
  const [coursePrice, setCoursePrice] = React.useState(175);
  const [masterClassPrice, setMasterclassPrice] = React.useState(15);
  const [error, setError] = React.useState("");

  const BuyCourse = (title, date) => {
    if (coursePrice === null) {
      return setError("აირჩიეთ თანხა");
    } else {
      dispatch(setEventTitle(title));
      dispatch(setEventDate(date));
      dispatch(setEventPrice(coursePrice));
      navigate("/checkout/ticket");
    }
  };
  const BuyMK = (title, date) => {
    if (coursePrice === null) {
      return setError("აირჩიეთ თანხა");
    } else {
      dispatch(setEventTitle(title));
      dispatch(setEventDate(date));
      dispatch(setEventPrice(masterClassPrice));
      navigate("/checkout/ticket");
    }
  };

  return (
    <Container>
      {/* <div style={{ margin: "10px 0" }}>
        <Typography>{LevelUpModiRus.date}</Typography>
      </div> */}
      {/* <Accordion className="cont">
        <AccordionSummary aria-controls="panel3a-content" id="panel3a-header">
          <Typography>{LevelUpModiRus.title}</Typography>
        </AccordionSummary>
        <AccordionDetails>
          <img src={cover5} style={{ width: "100%", marginBottom: "15px" }} />
          <Typography>{LevelUpModiRus.date}</Typography>
          <Typography>{LevelUpModiRus.location}</Typography>
          <Typography>{LevelUpModiRus.for}</Typography>
          <Typography>{LevelUpModiRus.schedule}</Typography>
          <Typography>{LevelUpModiRus.price}</Typography>
        </AccordionDetails>
       
      </Accordion>
      <div style={{ margin: "10px 0" }}>
        <Typography>{LevelUpModiGeo.date}</Typography>
      </div>
      <Accordion className="cont">
        <AccordionSummary aria-controls="panel3a-content" id="panel3a-header">
          <Typography>{LevelUpModiGeo.title}</Typography>
        </AccordionSummary>
        <AccordionDetails>
          <img src={cover6} style={{ width: "100%", marginBottom: "15px" }} />
          <Typography>{LevelUpModiGeo.location}</Typography>
          <Typography>{LevelUpModiGeo.for}</Typography>
          <Typography>{LevelUpModiGeo.schedule}</Typography>
          <Typography>{LevelUpModiGeo.price}</Typography>
        </AccordionDetails>
        
      </Accordion> */}
      {/* <div style={{ margin: "10px 0" }}>
        <Typography>{BasicCourseRusLanguage.date}</Typography>
      </div>
      <Accordion className="cont">
        <AccordionSummary aria-controls="panel2a-content" id="panel2a-header">
          <Typography>{BasicCourseRusLanguage.title}</Typography>
        </AccordionSummary>
        <AccordionDetails>
          <img src={cover2} style={{ width: "100%", marginBottom: "15px" }} />
          <Typography>{BasicCourseRusLanguage.date}</Typography>
          <Typography>{BasicCourseRusLanguage.location}</Typography>
          <Typography>{BasicCourseRusLanguage.for}</Typography>
          <Typography>{BasicCourseRusLanguage.schedule}</Typography>
          <Typography>{BasicCourseRusLanguage.price}</Typography>
        </AccordionDetails>
      </Accordion> */}
      <div style={{ margin: "10px 0" }}>
        <Typography>{BasicCourseGeoLanguage.date}</Typography>
      </div>
      <Accordion className="cont">
        <AccordionSummary aria-controls="panel1a-content" id="panel1a-header">
          <Typography>{BasicCourseGeoLanguage.title}</Typography>
        </AccordionSummary>
        <AccordionDetails>
          <img src={cover2} style={{ width: "100%", marginBottom: "15px" }} />
          <Typography>{BasicCourseGeoLanguage.date}</Typography>
          <Typography>{BasicCourseGeoLanguage.location}</Typography>
          <Typography>{BasicCourseGeoLanguage.for}</Typography>
          <Typography>{BasicCourseGeoLanguage.schedule}</Typography>
          <Typography>{BasicCourseGeoLanguage.price}</Typography>
        </AccordionDetails>
      </Accordion>
      {/* <div style={{ margin: "10px 0" }}>
        <Typography>{LevelUpLamiRus.date}</Typography>
      </div> */}
      {/* <Accordion className="cont">
        <AccordionSummary aria-controls="panel3a-content" id="panel3a-header">
          <Typography>{LevelUpLamiRus.title}</Typography>
        </AccordionSummary>
        <AccordionDetails>
          <img src={cover3} style={{ width: "100%", marginBottom: "15px" }} />
          <Typography>{LevelUpLamiRus.date}</Typography>
          <Typography>{LevelUpLamiRus.location}</Typography>
          <Typography>{LevelUpLamiRus.for}</Typography>
          <Typography>{LevelUpLamiRus.schedule}</Typography>
          <Typography>{LevelUpLamiRus.price}</Typography>
        </AccordionDetails>
      </Accordion>
      <div style={{ margin: "10px 0" }}>
        <Typography>{LevelUpLamiGeo.date}</Typography>
      </div> */}
      {/* <Accordion className="cont">
        <AccordionSummary aria-controls="panel3a-content" id="panel3a-header">
          <Typography>{LevelUpLamiGeo.title}</Typography>
        </AccordionSummary>
        <AccordionDetails>
          <img src={cover4} style={{ width: "100%", marginBottom: "15px" }} />
          <Typography>{LevelUpLamiGeo.date}</Typography>
          <Typography>{LevelUpLamiGeo.location}</Typography>
          <Typography>{LevelUpLamiGeo.for}</Typography>
          <Typography>{LevelUpLamiGeo.schedule}</Typography>
          <Typography>{LevelUpLamiGeo.price}</Typography>
        </AccordionDetails>
      </Accordion> */}
    </Container>
  );
};

export default Events;

const Container = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding-top: 7vw;
  min-height: 90vh;

  @media only screen and (max-width: 600px) {
    margin-top: 20vw;
  }

  .cont {
    width: 60vw;
    padding: 1vw;
    @media only screen and (max-width: 600px) {
      width: 90vw;
      padding: 3vw;
    }
  }

  .prices {
    width: 120px;
    cursor: pointer;
    padding: 0.2vw;
    borde-radius: 0.2vw;
    margin-bottom: 15px;

    :focus {
      outline: none;
    }
  }
`;

const Wrapper = styled.div`
  width: 60vw;
  height: 37vw;
  border-radius: 0.5vw;
  background: #e7f5ff;
  display: flex;
  flex-direction: column;
  justify-content: space-evenly;
  align-items: center;
  padding-bottom: 1vw;
  margin-top: 2vw;

  @media only screen and (max-width: 600px) {
    width: 85vw;
    height: auto;
    border-radius: 1vw;
    gap: 3vw;
    padding: 3vw 3vw 5vw 3vw;
  }

  .icon {
    font-size: 1.2vw;

    @media only screen and (max-width: 600px) {
      font-size: 4.5vw;
    }
  }
`;

const Button = styled.div`
  padding: 0.3vw 1vw 0.4vw 1vw;
  border-radius: 0.5vw;
  background: ${(props) => (props.remove ? "#ccc" : "#31a65e")};
  color: white;
  width: 12vw;
  height: 2vw;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  font-weight: bold;

  @media only screen and (max-width: 621px) {
    padding: 0.9vw 2vw 1.2vw 2vw;
    border-radius: 1.5vw;
    width: 40vw;
    height: 6vw;
    font-size: 3vw;
  }
`;
