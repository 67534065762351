import React, { useState } from "react";
import styled from "styled-components";
import { useSelector } from "react-redux";
import logo from "../assets/logo.png";

const BookReader = () => {
  React.useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  const us = useSelector((state) => state.storeMain.user);
  let user;
  if (us?.length > 0) {
    user = JSON?.parse(us);
  }

  let book;
  if (user?.book.name.toLowerCase().includes("ka")) {
    book =
      "https://drive.google.com/file/d/1tfVlFJSGfGljMLBFTpHq5EFH5yIWmPPd/preview";
  } else if (user?.book.name.toLowerCase().includes("ua")) {
    book =
      "https://drive.google.com/file/d/1sX0gJSurc-DOD4eR4XfcYSt1hPfM0nwZ/preview";
  } else if (user?.book.name.toLowerCase().includes("ru")) {
    book =
      "https://drive.google.com/file/d/1eckFxnAml_E69bntvP9FTeHG8J6TZGX3/preview";
  }

  return (
    <Container>
      <Wrapper>
        <div
          style={{
            width: "100%",
            position: "relative",
            display: "flex",
            justifyContent: "flex-end",
          }}
        >
          <div
            style={{
              width: "70px",
              height: "70px",
              position: "absolute",
              borderRadius: "5px",
              marginRight: "12px",
              marginTop: "2px",
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
              background: "#fff",
            }}
          >
            <img src={logo} width="60px" alt="elan georgia" />
          </div>
        </div>
        <Frame
          src={book}
          width="100%"
          height="100%"
          allow="autoplay"
          frameborder="0"
          scrolling="no"
          seamless=""
        ></Frame>
      </Wrapper>
    </Container>
  );
};

export default BookReader;

const Container = styled.div`
  width: 100%;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding-top: 5vw;

  @media only screen and (max-width: 600px) {
    margin-top: 15vw;
  }
`;

const Wrapper = styled.div`
  width: 60vw;
  border-radius: 0.5vw;
  background: #e7f5ff;
  display: flex;
  flex-direction: column;
  justify-content: space-evenly;
  align-items: center;
  padding-bottom: 1vw;
  margin-top: 2vw;

  @media only screen and (max-width: 600px) {
    width: 100vw;
    height: auto;
    border-radius: 1vw;
    // gap: 3vw;
    padding-bottom: 5vw;
  }

  .icon {
    font-size: 1.2vw;

    @media only screen and (max-width: 600px) {
      font-size: 4.5vw;
    }
  }
`;

const Frame = styled.iframe`
  width: 60vw;
  height: 80vh;

  @media only screen and (max-width: 600px) {
    width: 100vw;
  }
`;
