import React from "react";

export const Year2021 = [
  { month: "January", start: 1609444801 },
  { month: "February", start: 1612123201 },
  { month: "March", start: 1614542401 },
  { month: "April", start: 1617220801 },
  { month: "May", start: 1619812801 },
  { month: "June", start: 1622491201 },
  { month: "July", start: 1625083201 },
  { month: "August", start: 1627761601 },
  { month: "September", start: 1630440001 },
  { month: "Octomber", start: 1633032001 },
  { month: "November", start: 1635710401 },
  { month: "December", start: 1638302401 },
];
export const Year2022 = [
  { month: "January", start: 1640980801 },
  { month: "February", start: 1643659201 },
  { month: "March", start: 1646078401 },
  { month: "April", start: 1648756801 },
  { month: "May", start: 1651348801 },
  { month: "June", start: 1654027201 },
  { month: "July", start: 1656619201 },
  { month: "August", start: 1659297601 },
  { month: "September", start: 1661976001 },
  { month: "Octomber", start: 1664568001 },
  { month: "November", start: 1667246401 },
  { month: "December", start: 1669838401 },
];
export const Year2023 = [
  { month: "January", start: 1672516801 },
  { month: "February", start: 1675195201 },
  { month: "March", start: 1677614401 },
  { month: "April", start: 1680292801 },
  { month: "May", start: 1682884801 },
  { month: "June", start: 1685563201 },
  { month: "July", start: 1688155201 },
  { month: "August", start: 1690833601 },
  { month: "September", start: 1693512001 },
  { month: "Octomber", start: 1696104001 },
  { month: "November", start: 1698782401 },
  { month: "December", start: 1701374401 },
];
export const Year2024 = [
  { month: "January", start: 1704052801 },
  { month: "February", start: 1706731201 },
  { month: "March", start: 1709236801 },
  { month: "April", start: 1711915201 },
  { month: "May", start: 1714507201 },
  { month: "June", start: 1717185601 },
  { month: "July", start: 1719777601 },
  { month: "August", start: 1722456001 },
  { month: "September", start: 1725134401 },
  { month: "Octomber", start: 1727726401 },
  { month: "November", start: 1730404801 },
  { month: "December", start: 1732996801 },
];
export const Year2025 = [
  { month: "January", start: 1735675201 },
  { month: "February", start: 1738353601 },
  { month: "March", start: 1740772801 },
  { month: "April", start: 1743451201 },
  { month: "May", start: 1746043201 },
  { month: "June", start: 1748721601 },
  { month: "July", start: 1751313601 },
  { month: "August", start: 1753992001 },
  { month: "September", start: 1756670401 },
  { month: "Octomber", start: 1759262401 },
  { month: "November", start: 1761940801 },
  { month: "December", start: 1764532801 },
];
