import React from "react";
import { useSelector } from "../../redux/main";

export const eng = {
  search: "Search Product...",
};

export const geo = {
  search: "პროდუქტის ძებნა..",
};

export const rus = {
  search: "Поиск продукта...",
};
