import React from "react";
import { useSelector } from "../../redux/main";

export const eng = {
  signup: "Register",
  firstname: "First Name",
  lastname: "Last Name",
  phone: "Phone Number",
  city: "City",
  adress: "Adress",
  password: "Password",
  confirm: "Confirm Password",
  login: "Log in",
  already: "Already have an Account",
};

export const geo = {
  signup: "რეგისტრაცია",
  firstname: "სახელი",
  lastname: "გვარი",
  phone: "მობილურის ნომერი",
  city: "ქალაქი",
  adress: "მისამართი",
  password: "პაროლი",
  confirm: "დაადასტურე პაროლი",
  login: "შესვლა",
  already: "უკვე გაქვთ ანგარიში",
  register: "რეგისტრაცია",
  email: "ელ-ფოსტა",
};

export const rus = {
  signup: "Авторизоваться",
  firstname: "First Name",
  lastname: "Last Name",
  phone: "Phone Number",
  city: "City",
  adress: "Adress",
  password: "პაროლი",
  confirm: "დაადასტურე პაროლი",
  login: "Log in",
  already: "У вас нет учетной записи",
  register: "Регистрация",
  email: "Эл. адрес",
};
