import React from "react";
import { useSelector } from "../../redux/main";

export const eng = {
  version: "Vesion",
  pages: "Pages",
  price: "Price",
  gel: "Gel",
  description: "Description",
  add: "Add to Cart",
  remove: "Remove from Cart",
  desc: `Includes All Brows & Eyelashes Procedures used ELAN Professional Line Products.

  – created for those who plan to become a professional eyebrow master, as well as for those who are looking for ways to improve their professional skills;
  
  – The book was created by professional ÉLAN eyebrow masters with many years of experience in the beauty industry;
  
  – All illustrations are specially created for the book – ÉLAN BROW BOOK – taking into account the opinions of experts-specialists;
  
  *after bought (max 1 hour) you will receive the Book link on your email address, that used in checkout form.`,
  ka: "georgian",
  en: "english",
  ru: "russian",
  ua: "ukrainian",
  reading: "Reading",
};

export const geo = {
  version: "ვერსია",
  pages: "გვერდები",
  price: "ფასი",
  gel: "ლარი",
  description: "აღწერა",
  add: "კალათაში დამატება",
  remove: "წაშლა",
  desc: `მოიცავს წარბების და წამწამების ყველა პროცედურას, ELAN
                  პროფესიონალური ხაზის პროდუქტებით. – შექმნილია მათთვის ვინც
                  გეგმავს გახდეს პროფესიონალი წარბების მასტერი, ასევე მათთვის
                  ვინც ეძებს გზებს გაიუმჯობესოს პროფესიული უნარები – წიგნი
                  შექმნილია ÉLAN-ის პროფესიონალი წარბის მასტერების მიერ,
                  რომელთაც მრავალწლიანი გამოცდილება აქვთ სილამაზის ინდუსტრიაში –
                  ყველა ილუსტრაცია სპეციალურად შექმნილია წიგნისთვის- ÉLAN BROW
                  BOOK - ექსპერტები-სპეციალისტების მოსაზრებების გათვალისწინებით
                  *შეძენის შემდეგ (მაქსიმუმ 1 საათი) თქვენ მიიღებთ წიგნის ბმულს
                  თქვენს ელ. ფოსტის მისამართზე, რომელიც გამოიყენება შეკვეთის
                  ფორმაში.`,
  ka: "ქართული",
  en: "ინგლისური",
  ru: "რუსული",
  ua: "უკრაინული",
  reading: "წაკითხვა",
};

export const rus = {
  version: "Версия",
  pages: "Страницы",
  price: "Цена",
  gel: "Лари",
  description: "Описание",
  add: "Добавить",
  remove: "Удалить",
  desc: `Включает все процедуры для бровей и ресниц с использованием продукции ELAN professional line

  – создана для тех, кто планирует овладеть профессией бровиста, и для бровистов, которые стремятся повысить профессиональную квалификацию;
  
  – над книгой работали профессиональные бровисты команды ÉLAN с многолетним опытом работы в сфере beauty
  
  — все иллюстрации разработаны специально для ÉLAN BROW BOOK с учетом экспертного мнения специалистов
  
  *после покупки (максимум 1 час) вы получите ссылку «Книга» на свой адрес электронной почты, указанный в форме оформления заказа.`,
  ka: "грузинский",
  en: "английский",
  ru: "русский",
  ua: "украинский",
  reading: "Читать",
};
