import React from "react";
import { useSelector } from "../../redux/main";

export const eng = {
  shop: "Shop",
  reading: "Reading",
  cart: "Cart",
  checkout: "Checkout",
};

export const geo = {
  shop: "მაღაზია",
  reading: "წაკითხვა",
  cart: "კალათა",
  checkout: "გაფორმება",
};

export const rus = {
  shop: "Магазин",
  reading: "Читать",
  cart: "Корзина",
  checkout: "Оформить",
};
