import React from "react";
import { useSelector } from "../../redux/main";

export const eng = {
  login: "Log in",
  forgot: "Forgot Password",
  dont: "Don't have an Account",
  register: "Register",
  email: "Email",
  button: "Send",
  reset: "Reset Password",
  sent: "New password sent to email",
  send: "Send to email",
  wrong: "Wrong email or password",
  password: "Password",
};

export const geo = {
  login: "შესვლა",
  forgot: "დაგავიწყდა პაროლი",
  dont: "არ გაქვს ანგარიში შექმნილი",
  register: "რეგისტრაცია",
  email: "ელ-ფოსტა",
  button: "გაგზავნა",
  reset: "პაროლის განახლება",
  sent: "განახლებული პაროლი გაგზავნილია ელ-ფოსტაზე",
  send: "ელ ფოსტაზე გაგზავნა",
  wrong: "არასწორი ელ-ფოსტა ან პაროლი",
  password: "პაროლი",
};

export const rus = {
  login: "Авторизоваться",
  forgot: "Забыли пароль",
  dont: "У вас нет учетной записи",
  register: "Регистрация",
  email: "Эл. адрес",
  button: "Отправить",
  reset: "Сброс пароля",
  sent: "Новый пароль отправлен на почту",
  send: "Отправить на электронной почте",
  wrong: "Wrong email or password",
  password: "пароль",
};
