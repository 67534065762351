import React from "react";
import { useSelector } from "../../redux/main";

export const eng = {
  hello: "Hello",
  email: "Email",
  phone: "Phone Number",
  adress: "Adress",
  password: "Password",
  coupon: "Coupon",
  reset: "Reset Password",
  sent: "New password sent to email",
  send: "Send to email",
};

export const geo = {
  hello: "გამარჯობა",
  email: "ელ ფოსტა",
  phone: "მობილურის ნომერი",
  adress: "მისამართი",
  password: "პაროლი",
  coupon: "კუპონი",
  reset: "პაროლის განახლება",
  sent: "განახლებული პაროლი გაგზავნილია ელ-ფოსტაზე!",
  send: "ელ ფოსტაზე გაგზავნა",
};

export const rus = {
  hello: "Привет",
  email: "Эл. адрес",
  phone: "Номер телефона",
  adress: "Адрес",
  password: "Пароль",
  coupon: "Купон",
  reset: "Сброс пароля",
  sent: "Новый пароль отправлен на почту",
  send: "Отправить на электронной почте",
};
