import React from "react";
import { useSelector } from "../../redux/main";

export const eng = {
  back: "Back to Cart",
  title: "Checkout Form",
  name: "Firstname & Lastname",
  email: "Email",
  phone: "Phone Number",
  city: "City",
  adress: "Adress",
  comment: "Comment",
  coupon: "Coupon",
  couponText: "Enter Coupon...",
  discount: "Discount",
  gel: "Gel",
  total: "Total",
  buy: "Buy",
  choiceDelivery: "Choice Delivery",
  standart: "Standart 1-2 Days (6 Gel)",
  express: "Express 12 Gel (Order Day)",
  pickUp: "Pick Up",
  regions: "Regions 1-4 Days (10 Gel)",
  delivery: "Delivery",
  order: "Your Order",
  subtotal: "Subtotal",
  loading: "Loading...",
};

export const geo = {
  back: "კალათაში დაბრუნება",
  title: "შეძენის ფორმა",
  name: "სახელი & გვარი",
  email: "ელ ფოსტა",
  phone: "მობილურის ნომერი",
  city: "ქალაქი",
  adress: "მისამართი",
  comment: "კომენტარი",
  coupon: "კუპონი",
  couponText: "შეიყვანეთ კუპონი...",
  discount: "ფასდაკლება",
  gel: "ლარი",
  total: "სრული ფასი",
  buy: "ყიდვა",
  choiceDelivery: "აირჩიე ტრანსპორტირება",
  standart: "სტანდარტული 1-2 დღე (6 ლარი)",
  express: "ექსპრესი 12 ლარი (შეკვეთის დღეს)",
  pickUp: "ადგილზე აღება",
  regions: "რეგიონი 1-4 დღე (10 ლარი)",
  delivery: "ტრანსპორტირება",
  order: "თქვენი შეკვეთა",
  subtotal: "ჯამი",
  loading: "იტვირთება...",
};

export const rus = {
  back: "Вернуться в корзину",
  title: "Форма оформления заказа",
  name: "Имя & фамилия",
  email: "Электронная почта",
  phone: "Номер телефона",
  city: "Город",
  adress: "Адрес",
  comment: "Комментарий",
  coupon: "Купон",
  couponText: "Введите купон...",
  discount: "Скидка",
  gel: "Лари",
  total: "Всего",
  buy: "Купить",
  choiceDelivery: "Доставка на выбор",
  standart: "Стандарт 1-2 дня (6 лари)",
  express: "Экспресс 12 лари (день заказа)",
  pickUp: "Подобрать",
  regions: "Регионы 1-4 дня (10 лари)",
  delivery: "Доставка",
  order: "Ваш заказ",
  subtotal: "Subtotal",
  loading: "Загрузка...",
};
